import { useTranslation } from "react-i18next";
import {SuccessStory} from './SuccessStories-style.js'


export default function SuccessStories(){
    const {t} = useTranslation()

    return (
        <SuccessStory>
        <div className="container">
                    <div className="text-center">
                        <h2>
                            <span>
                                {t('successStories.title')}
                            </span>
                        </h2>
                    </div>

                    <div className="row mt-4">

                        <div className="col-lg-12 text-center">
                            <div className="stories-content">
                                <p>  {t('successStories.description')}</p>
                            </div>
                        </div>
                    </div>
                </div>
        </SuccessStory>
    )
}