import { useTranslation } from 'react-i18next';
import Navbar from "../../Header/Navbar/Navbar";
import ServicesBanner from "../ServicesBanner/ServicesBanner";
import Footer from "../../Footer/Footer";
import HeadingH2 from "../../HeadingComponent/HeadingH2";
import SuccessStories from "../../Home/SuccessStories/SuccessStories";
import React, { useEffect } from 'react';
import ScrollToTop from '../../ScrollToTop';

export default function LegalRegulation() {
    const { t } = useTranslation();
    return (
        <>
            <ScrollToTop />
            <Navbar />

            <ServicesBanner deskbanner="assets/images/services/WechatIMG2.jpg" mobbanner="assets/images/services/WechatIMG2.jpg" />
            <div className="services-card-content">
                <br />
                <div className="container">
                    <HeadingH2 title={t("legalservices.title")} />

                    <p>{t("legalservices.intro")}</p>
                </div>
            </div>
            <div className="services-card-content">
                <br />
                <div className="container">

                    <h2 className="svtitle">{t("legalservices.services.propertyTransactions")}</h2>

                    <p>{t("legalservices.services.propertyTransactionsDesc")}</p>
                </div>
            </div>
            <div className="services-card-content">
                <br />
                <div className="container">
                    <h2 className="svtitle">{t("legalservices.services.businessRegistration")}</h2>

                    <p>{t("legalservices.services.businessRegistrationDesc")}</p>
                </div>
            </div>
            <div className="services-card-content">
                <br />
                <div className="container">
                    <h2 className="svtitle">{t("legalservices.services.visaRequirements")}</h2>

                    <p>{t("legalservices.services.visaRequirementsDesc")}</p>
                </div>
            </div>
            <div className="services-card-content">
                <br />
                <div className="container">

                    <h2 className="svtitle">{t("legalservices.services.contractDraftingReview")}</h2>

                    <p>{t("legalservices.services.contractDraftingReviewDesc")}</p>
                </div>
            </div>
            <div className="services-card-content">
                <br />
                <div className="container">
                    <h2 className="svtitle">{t("legalservices.services.employmentLaw")}</h2>
                    <p>{t("legalservices.services.employmentLawDesc")}</p>
                </div>
            </div>
            <div className="services-card-content">
                <br />
                <div className="container">
                    <h2 className="svtitle">{t("legalservices.services.intellectualProperty")}</h2>
                    <p>{t("legalservices.services.intellectualPropertyDesc")}</p>
                </div>
            </div>
            <div className="services-card-content">
                <br />
                <div className="container">
                    <h2 className="svtitle">{t("legalservices.services.familyLaw")}</h2>
                    <p>{t("legalservices.services.familyLawDesc")}</p>
                </div>
            </div>
            <div className="services-card-content">
                <br />
                <div className="container">
                    <h2 className="svtitle">{t("legalservices.services.civilLitigation")}</h2>
                    <p>{t("legalservices.services.civilLitigationDesc")}</p>
                </div>
            </div>
            <div className="services-card-content">
                <br />
                <div className="container">
                    <h2 className="svtitle">{t("legalservices.whyChooseUs.title")}</h2>
                    <ul>
                        <li>{t("legalservices.whyChooseUs.experiencedLawyers")}: {t("legalservices.whyChooseUs.experiencedLawyersDesc")}</li>
                        <li>{t("legalservices.whyChooseUs.personalizedApproach")}: {t("legalservices.whyChooseUs.personalizedApproachDesc")}</li>
                        <li>{t("legalservices.whyChooseUs.clientCentered")}: {t("legalservices.whyChooseUs.clientCenteredDesc")}</li>
                        <li>{t("legalservices.whyChooseUs.confidentiality")}: {t("legalservices.whyChooseUs.confidentialityDesc")}</li>
                    </ul>
                </div>
            </div>
            <div className="services-card-content">
                <br />
                <div className="container">
                    <p>{t("legalservices.getInTouch")}</p>
                    <p>{t("legalservices.scheduleConsultation")}</p>
                </div>
            </div>
            <SuccessStories />
            <Footer ishome={false} />
        </>
    )
}
