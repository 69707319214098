import Navbar from "../../Header/Navbar/Navbar";
import Banner from "../BannerComponent/Banner";
import { conf } from "../../../config"
import {
  ContactContainer,
  ContactForm,
  ContactDetails
} from "./ContactUs-style";
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import Newsletter from "../../NewsletterComponent/Newletter";
import Footer from "../../Footer/Footer";
import HeadingOne from "../Headings/HeadingOne";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function ContactUs() {
  const { t } = useTranslation()
  const [values, setValues] = useState({
    name: "",
    email: "",
    phonenumber: "",
    country: "",
    subject: "", // Add subject property
    description: "",
  });

  const changeHandler = (field, val) => {
    setValues((v) => ({ ...v, [field]: val }));
  };
  const submitHandler = (e) => {
    e.preventDefault();
    const body = { ...values };

    // Disable the button
    const sendButton = document.querySelector(".sendmsg");
    sendButton.disabled = true;

    fetch(`${conf.server}/api/contactus`, { // Updated URL with /api prefix
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((result) => result.json())
      .then((res) => {
        // Clear the form
        setValues((pv) => ({
          name: "",
          email: "",
          phonenumber: "",
          country: "",
          subject: "",
          description: "",
        }));

        // Show a confirmation message to the user
        const successMsg = `${t('responseReceived')}`
        toast.success(successMsg);

        // Enable the button
        sendButton.disabled = false;

        // Scroll to the top of the page
        window.scrollTo({ top: 0, behavior: "smooth" });
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Navbar />

      <Banner
        title={t('contactUs')}
        subtitle={t('helpUs')}
        homeurl="/"
        activelink="Contact Us"
      />

      <ContactContainer>
        <div className="container">
          <HeadingOne title={t('contactUs')} />
          <p>
            {t('getInTouch')}
          </p>

          <ContactForm>
            <form onSubmit={submitHandler}>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="mb-2 mt-2">
                    <label htmlFor="name" className="form-label">
                      {t('yourName')}
                    </label>
                    <input
                      type="name"
                      className="form-control"
                      placeholder={t("yourName")}
                      name="name"
                      onChange={(e) => changeHandler("name", e.target.value)}
                      value={values.name}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-2 mt-2">
                    <label htmlFor="email" className="form-label">
                      {t('email')}
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder={t('email')}
                      name="email"
                      onChange={(e) => changeHandler("email", e.target.value)}
                      value={values.email}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="mb-3 mt-2">
                    <label htmlFor="name" className="form-label">
                      {t('phoneNumber')}
                    </label>
                    <input
                      type="name"
                      className="form-control"
                      placeholder={t('phoneNumber')}
                      name="phone"
                      onChange={(e) =>
                        changeHandler("phonenumber", e.target.value)
                      }
                      value={values.phonenumber}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-2 mt-2">
                    <label htmlFor="country" className="form-label">
                      {t('country')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t('country')}
                      name="country"
                      onChange={(e) => changeHandler("country", e.target.value)}
                      value={values.country}
                      required
                    />
                  </div>
                </div>
                <div className="mb-2 mt-2">
                  <label htmlFor="subject" className="form-label">
                    {t('subject')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t('subject')}
                    name="subject" // Update name attribute to "subject"
                    onChange={(e) => changeHandler("subject", e.target.value)}
                    value={values.subject}
                    required
                  />

                </div>
              </div>
              <div className="mt-3">
                <label htmlFor="comment" className="form-label">
                  {t('message')}
                </label>
                <textarea
                  className="form-control"
                  rows="6"
                  id="comment"
                  name="message"
                  placeholder={t('enterMsg')}
                  onChange={(e) => changeHandler("description", e.target.value)}
                  value={values.description}
                  required
                ></textarea>
              </div>
              <div className="mt-4">
                <button
                  type="submit"
                  className="btn btn-primary sendmsg"
                  value="send"
                >
                  {t('sendMessage')}
                </button>
              </div>
            </form>
          </ContactForm>

          <ContactDetails>
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="contact-card">
                  <FaMapMarkerAlt
                    style={{ color: "#008FFF", fontSize: "30px" }}
                  />
                  <h5>{t('address')}</h5>
                  <p>
                    5th Floor, Forum Tower, 184 Ratchadaphisek Rd, Khwaeng Huai Khwang, Huai Khwang, Bangkok 10310, Thailand
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="contact-card">
                  <FaEnvelope style={{ color: "#008FFF", fontSize: "30px" }} />
                  <h5>{t('emailAddress')}</h5>
                  <p>
                    <a href="mailto:info@mingtai.org">
                      info@mingtai.org
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="contact-card">
                  <FaPhoneAlt style={{ color: "#008FFF", fontSize: "30px" }} />
                  <h5>{t('phoneNumber')}</h5>
                  <p>
                    <a href="tel:+66 (0) 2-245-5634">+66 (0) 2-245-5634</a>
                  </p>
                  <p>
                    <a href="tel:+66 (0) 88-090-7555">+66 (0) 88-090-7555</a>
                  </p>
                  <p>
                    <a href="tel:+66 (0) 61-951-6180">+66 (0) 61-951-6180</a>
                  </p>
                </div>
              </div>
            </div>
          </ContactDetails>

          <div className="map">
            <iframe
              title="googleMap"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.0910256783504!2d100.57172921488691!3d13.773383990333567!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29e80b03f3845%3A0xc12fc353881bb2c9!2sForum%20Tower!5e0!3m2!1sen!2sin!4v1670408873413!5m2!1sen!2sin"
              width="100%"
              height="450"
            ></iframe>
          </div>
        </div>
      </ContactContainer>

      <Newsletter />

      <Footer ishome={false} />
    </>
  );
}
