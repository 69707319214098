import { BrowserRouter, Routes, Route } from "react-router-dom";
import { I18nextProvider } from 'react-i18next';
import i18n from "../src/utils/i18n";
import Home from "./components/Home/Home";
import Services from "./components/pages/Services/Services";
import BankAccount from "./components/pages/Services/BankDriving";


import Visas from "./components/pages/Services/Visas";
import ContactUs from "./components/pages/ContactUs/ContactUs";
import OurTeam from "./components/pages/OurTeamComponent/OurTeam";
import Tech from "./components/pages/Services/Tech"
import Legal from "./components/pages/Services/LegalRegulation";
import ClientTestimonials from "./components/pages/TestimonialsComponent/ClientTestimonials";
import AboutUs from "./components/pages/about/AboutUs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CarService from "./components/pages/Services/CarService";
import AdditionalService from "./components/pages/Services/AdditionalService";
import CompanyReg from "./components/pages/Services/CompanyReg";
import RealEstate from "./components/pages/Services/RealEstate";
import Media from "./components/pages/Services/Media";


function App() {

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="services" element={<Services />} />
            <Route path="media" element={<Media />} />
            <Route path="real-estate" element={<RealEstate />} />
            <Route path="car-service" element={<CarService />} />
            <Route path="tech" element={<Tech />} />


            <Route
              path="bank-account"
              element={<BankAccount />}
            />
            <Route path="company-reg" element={<CompanyReg />} />
            <Route path="visas" element={<Visas />} />
            <Route
              path="additional-service"
              element={<AdditionalService />}
            />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="our-team" element={<OurTeam />} />
            <Route path="visas" element={<Visas />} />
            <Route path="legal" element={<Legal />} />
            <Route path="testimonials" element={<ClientTestimonials />} />

          </Routes>
        </BrowserRouter>
      </I18nextProvider>
    </>
  );
}

export default App;
