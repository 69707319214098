import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FaLaptop, FaCubes } from "react-icons/fa";
import Footer from "../../Footer/Footer";
import Navbar from "../../Header/Navbar/Navbar";
import ServicesBanner from "../ServicesBanner/ServicesBanner";

const ServicesContainer = styled.div`
  padding: 60px 0;
`;

const ServicesRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ServiceCard = styled.div`
  flex-basis: calc(50% - 20px);
  padding: 20px;
  background-color: #f5f7fa;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
  border: 1px solid #e1e5eb;

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
`;

const ServiceTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #2d3748;
`;

const ServiceText = styled.p`
  margin-bottom: 20px;
  color: #4a5568;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  svg {
    margin-right: 10px;
    color: #667eea;
  }
`;

export default function Services() {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <ServicesBanner
        deskbanner="assets/images/services/WechatIMG9.jpg"
        mobbanner="assets/images/services/WechatIMG9.jpg"
      />
      <ServicesContainer>
        <div className="container">
          <ServicesRow>
            <ServiceCard>
              <ServiceTitle>{t("webAndMobileDevelopment.title")}</ServiceTitle>
              <ServiceText>{t("webAndMobileDevelopment.description")}</ServiceText>
              <FeatureList>
                <FeatureItem>
                  <FaLaptop size={20} />
                  {t("webAndMobileDevelopment.features.customSolutions")}
                </FeatureItem>
                <FeatureItem>
                  <FaLaptop size={20} />
                  {t("webAndMobileDevelopment.features.userCentricDesign")}
                </FeatureItem>
                <FeatureItem>
                  <FaLaptop size={20} />
                  {t("webAndMobileDevelopment.features.scalability")}
                </FeatureItem>
                <FeatureItem>
                  <FaLaptop size={20} />
                  {t("webAndMobileDevelopment.features.crossPlatform")}
                </FeatureItem>
              </FeatureList>
            </ServiceCard>
            <ServiceCard>
              <ServiceTitle>{t("threeDConfigurator.title")}</ServiceTitle>
              <ServiceText>{t("threeDConfigurator.description")}</ServiceText>
              <FeatureList>
                <FeatureItem>
                  <FaCubes size={20} />
                  {t("threeDConfigurator.features.realisticVisualization")}
                </FeatureItem>
                <FeatureItem>
                  <FaCubes size={20} />
                  {t("threeDConfigurator.features.interactiveCustomization")}
                </FeatureItem>
                <FeatureItem>
                  <FaCubes size={20} />
                  {t("threeDConfigurator.features.seamlessIntegration")}
                </FeatureItem>
                <FeatureItem>
                  <FaCubes size={20} />
                  {t("threeDConfigurator.features.analyticsInsights")}
                </FeatureItem>
              </FeatureList>
            </ServiceCard>
          </ServicesRow>
        </div>
      </ServicesContainer>
      <Footer ishome={false} />
    </>
  );
}
