import Footer from "../../Footer/Footer";
import Navbar from "../../Header/Navbar/Navbar";
import Testimonials from "../../Home/Testimonials/Testimonials";
import Newsletter from "../../NewsletterComponent/Newletter";
import styled from "styled-components";
import Banner from "../BannerComponent/Banner";
import { useTranslation } from "react-i18next";

const ClientSayCantainer = styled.div`
    width: 100%;
    @media screen and (max-width: 767px){
        margin-bottom: 145px;
    }
    h3{
        max-width: 680px !important;
        font-family: "Nunito",sans-serif;
        text-transform: capitalize;
        color: #1c1d3e;
        font-size: 2.2rem;
        @media screen and (max-width: 576px){
            line-height: 34px;
            font-size: 1.8rem;
        }
    }
`;


export default function ClientTestimonials(){
    const { t } = useTranslation()
    return(
        <>
          <Navbar />

          <Banner title={t('testimonials')} subtitle={t('praiseAboutMingTai')} homeurl="/"  activelink="Testimonials" />

            <ClientSayCantainer>
                <Testimonials />
            </ClientSayCantainer> 

          
          <Newsletter />

          <Footer ishome={false} />

        </>
    )
}