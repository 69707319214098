import React, { useEffect } from "react";
import { BannerContainer, ButtonCard } from "./Banner-style";
import { BsWhatsapp } from "react-icons/bs";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ScrollToTop from "../../ScrollToTop";
export default function Banner() {
    const { t } = useTranslation()
    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);

    return (
        <>
            <BannerContainer>
            <ScrollToTop />
                <div className="container-fluid">
                    <div className="row py-5 align-items-end">
                        <div className="col-xl-3 col-lg-6 col-md-6">
                            <div className="banner-img">

                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="banner-content">
                                <h3 data-aos="fade-up"><span className="titleText">
                                Mingtai</span>
                                </h3>

                                <ButtonCard className="connects-btn d-flex mpt-5" data-aos="fade-up" data-aos-duration='2000'>

                                    <button type="button" className="btn contactBtn">
                                        <NavLink to="/contact-us" className="nav-link">{t('contactUsNow')}</NavLink>
                                    </button>
                                    <button type="button" className="btn consultBtn">
                                        <a href="https://wa.me/+66628654568" target="_blank" rel="noopener noreferrer" className="nav-link"><BsWhatsapp /> {t('talkToConsultant')}</a>
                                    </button>
                                </ButtonCard>
                                <br></br>
                                <span className="row py-4 align-items-end cl">{t('platformText')}
                                </span>

                                <div className="circle-img">

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </BannerContainer>
        </>
    )
}