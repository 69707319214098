import styled from "styled-components";
export const SuccessStory = styled.section`
     width: 100%;

.story-img{
    height: 550px;
    object-fit: cover;
    border-radius: 6px;
}

h2{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 41px;
    margin-bottom: 40px;
    @media screen and (max-width: 576px){
        font-size: 1.8rem;
        line-height: 38px;
        margin-bottom: 20px;
    }
    span{
        color: #012fa7;
    }
}

.story-blue-txt{
    color: #012fa7;
}
`;

