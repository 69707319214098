import Navbar from "../../Header/Navbar/Navbar";
import { TeamContainer, HeadTitle, ConsultContainer, MessageCEO } from "./OurTeam-style";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import TeamExpertCards from "../about/ExpertTeamCards";
import { FaUsers } from "react-icons/fa";
import { BsFillChatRightTextFill } from "react-icons/bs";
import Newsletter from "../../NewsletterComponent/Newletter";
import Footer from "../../Footer/Footer";
import HeadingOne from "../Headings/HeadingOne";
import Banner from "../BannerComponent/Banner";
import { useTranslation } from "react-i18next";

export default function OurTeam() {
  const { t } = useTranslation()
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <>
      <Navbar />

      <Banner title={t('joinOurTeam.title')} subtitle={t('joinOurTeam.description')} />


      <div className="container">
        <HeadTitle>
          <div className="title-effect title-effect-2">
            <div className="ellipse text-center"></div> <span><FaUsers /></span>
          </div>
          <h5>{t('creativeTeam')}</h5>
          <HeadingOne title={t('expertTeam')} />
          <p>{t('jobDescription')}</p>
        </HeadTitle>

        <div className="row">

          {/* <MessageCEO>
            <div className="row">
              <div className="alineanim">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <img src="assets/images/team/sunjay.jpg" alt="" />
                </div>

              </div>

            </div>
            <h1>Message from The CEO</h1>
            <h2>As a customer-focused company, our aim is to generate sustainable profit growth through the creation of new value and the delivery of innovative solutions.
            </h2>
            <h2>At VARA EdTech, we value and celebrate every accomplishment, big or small, to maintain a positive and motivated work environment.</h2>

          </MessageCEO> */}

        </div>
        <div className="row justify-content-center">
          <div className="row">
            <div className="col-lg-12">
              <TeamContainer>
                <Carousel className="" responsive={responsive} autoPlay infinite={true} autoPlaySpeed={5000} arrows={false} swipeable={true}>
                  <div><TeamExpertCards image="assets/images/team/1.jpeg" title="Zeming Li" designation="Chairman and General Manager" /></div>
                  <div><TeamExpertCards image="assets/images/team/2.jpeg" title="Lilian Liu" designation="Executive Director" /></div>
                  <div><TeamExpertCards image="assets/images/team/3.jpeg" title="Lucien Liu" designation="Financial Director" /></div>
                  <div><TeamExpertCards image="assets/images/team/4.jpeg" title="Nic" designation="Transport Manager" /></div>
                  <div><TeamExpertCards image="assets/images/team/5.jpeg" title="Rui Li" designation="Global Visa Manager" /></div>
                  <div><TeamExpertCards image="assets/images/team/6.jpeg" title="May Li" designation="Key Account Manager" /></div>
                  <div><TeamExpertCards image="assets/images/team/7.jpeg" title="Lily" designation="Legal Director" /></div>
                  <div><TeamExpertCards image="assets/images/team/8.jpeg" title="Yangyang" designation="Director of Transportation" /></div>
                  <div><TeamExpertCards image="assets/images/team/9.jpeg" title="Jinmei Duan" designation="Visa Department" /></div>
                  <div><TeamExpertCards image="assets/images/team/10.jpeg" title="Joey Zhang" designation="Legal Specialist" /></div>
                  <div><TeamExpertCards image="assets/images/team/11.jpeg" title="Jokoe" designation="Transport Driver" /></div>
                  <div><TeamExpertCards image="assets/images/team/12.jpeg" title="Qilin Wang" designation="Chief Assistant" /></div>
                  <div><TeamExpertCards image="assets/images/team/13.jpeg"  title="Sunjay Kumar" designation="Chief Technology Officer"/></div>
                </Carousel>
              </TeamContainer>
            </div>
          </div>
        </div>
      </div>

      <ConsultContainer>
        <div className="container">
          <div className="alineanim">
            <img src="assets/images/team/lineam.png" alt="" />
          </div>
          <h2>{t('consultWithUs')}</h2>

          <a href="https://wa.me/+66948877955" className="letstalk" target="_blank" rel="noopener noreferrer"><BsFillChatRightTextFill />{t('letstalk')}</a>

          <div className="alineanim1">
            <img src="assets/images/team/lineam.png" alt="" />
          </div>
        </div>
      </ConsultContainer>

      <Newsletter />
      <Footer ishome={false} />

    </>
  )
}