import styled from "styled-components";
import Footer from "../../Footer/Footer";
import Navbar from "../../Header/Navbar/Navbar";
import HeadingH2 from "../../HeadingComponent/HeadingH2";
import HeadingThree from "../Headings/HeadingThree";
import ServicesBanner from "../ServicesBanner/ServicesBanner";
import { BiCheckShield, BiLock } from "react-icons/bi";
import { FiLayers, } from "react-icons/fi";
import { BsAward } from "react-icons/bs";
import SuccessStories from "../../Home/SuccessStories/SuccessStories";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
const SecurityServices = styled.section`
    .it-services-cards{
        padding: 20px;
        box-shadow: 0px 1px 20px 0px rgb(0 0 0 / 13%);
        margin-bottom: 30px;
        border-radius: 5px;
        transition: all 0.4s ease-in-out;
        &:hover{
            transform: translateY(-3px);
            box-shadow: 0px 4px 30px 0px rgb(0 0 0 / 20%);
        }
        h4{
            font-size: 18px;
            color: #444;
            font-weight: 600;
            display: flex;
            align-items: center;
            column-gap: 8px;
            span{
                font-size: 50px;
                color: #2575fc;
            }
        }
    }
`;

export default function RealEstate() {
    const { t } = useTranslation();

    useEffect(() => {
        console.log("Scrolling to top...");
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }, []);
    
    return (
        <>
            <Navbar />
            <ServicesBanner deskbanner="assets/images/services/WechatIMG4.jpg" mobbanner="assets/images/services/WechatIMG4.jpg" />
            <div className="services-card-content">
                <div className="container">
                    <HeadingH2 title={t('realEstate.title')} />
                    <h2 className="svtitle">{t('realEstate.subtitle')}</h2>
                    <p>{t('realEstate.paragraph1')}</p>
                    <h2 className="svtitle">{t('realEstate.subtitle2')}</h2>
                    <p>{t('realEstate.paragraph2')}</p>
                </div>
            </div>
            <SecurityServices>
                <div className="container">
                    <div className="text-center">
                        <HeadingThree title={t('realEstate.securityServicesTitle')} />
                    </div>
                    <div className="row mt-5">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="it-services-cards">
                                <h4><span><BiCheckShield /></span> {t('realEstate.services.card1Title')}</h4>
                                <p>{t('realEstate.services.card1Description')}</p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="it-services-cards">
                                <h4><span><BiLock /></span> {t('realEstate.services.card2Title')}</h4>
                                <p>{t('realEstate.services.card2Description')}</p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="it-services-cards">
                                <h4><span><FiLayers /></span> {t('realEstate.services.card3Title')}</h4>
                                <p>{t('realEstate.services.card3Description')}</p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="it-services-cards">
                                <h4><span><BsAward /></span> {t('realEstate.services.card4Title')}</h4>
                                <p>{t('realEstate.services.card4Description')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </SecurityServices>

            <Footer ishome={false} />
        </>
    )
}
