import React from 'react';
import HeadingH2 from "../../HeadingComponent/HeadingH2";
import { FaInfo } from "react-icons/fa";
import { MainContainer, ContentCard, MisionLisit, List, ImageCard, Button } from "./OurVisionMision-style";
import { useTranslation } from 'react-i18next';

export default function OurVisionMision() {
    const { t } = useTranslation();
    const youtubeVideoId = "rQU7LoGYnCw"; // Extract the video ID from the YouTube link
    const youtubeEmbedUrl = `https://www.youtube.com/embed/${youtubeVideoId}`;

    return (
        <MainContainer>
            <div className="container-fluid">
                <div className="row mvision">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                        <ImageCard className="video-column vm-desktop-view">
                            <iframe
                                width="100%"
                                height="600" // Adjust the height as needed
                                src={youtubeEmbedUrl}
                                title="YouTube Video"
                                frameBorder="0"
                                allowFullScreen
                            />
                        </ImageCard>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6">
                        <ContentCard>
                            <br></br>
                            <div className="title-effect title-effect-2">
                                <div className="ellipse"></div> <span><FaInfo /></span>
                            </div>
                            <HeadingH2 title={t('ourVisionAndMission')} />
                            <div className="vm-mobile-view">
                                <ImageCard className="image-column">
                                    <iframe
                                        width="100%"
                                        height="600" // Adjust the height as needed
                                        src={youtubeEmbedUrl}
                                        title="YouTube Video"
                                        frameBorder="0"
                                        allowFullScreen
                                    />
                                </ImageCard>
                            </div>
                            <p>{t('ourVision')}</p>
                            <p>{t('ourMission')}</p>
                            <MisionLisit>
                                    <List>{t('visaService')}</List>
                                    <List>{t('legalRegulation')}</List>
                                    <List>{t('bankingService')}</List>
                                    <List>{t('propertySaleRent')}</List>
                                    <List>{t('businessSetup')}</List>
                                    <List>{t('vehicleService')}</List>
                                    <List>{t('paperWorkAssistance')}</List>
                                    <List>{t('digitalMedia')}</List>
                                   
                                </MisionLisit>
                        </ContentCard>
                    </div>
                </div>
            </div>
        </MainContainer>
    );
}
