import styled from "styled-components";

export const MainContainer = styled.section`
     width: 100%;
     @media screen and (max-width: 576px){
        margin-top: 20px;
    }
    .titel-head{
        flex-direction: column;
        justify-content: center;
        h3{
            max-width: 485px;
            margin: 10px auto;
            text-align: center;
            @media screen and (max-width: 576px){
                width: 100%;
            }
        }
       
    }
`;

export const TestimonialContent = styled.div`
    width: 100%;
    position: relative;
   
    .react-multi-carousel-list  {
        padding: 50px 0px;
        position: static;
        @media screen and (max-width: 576px){
            padding-top: 0px;
        }

        .react-multi-carousel-dot-list {
            li{
                
                button{
                    transition: all 0.4s ease-in-out;
                    width: 130px;
                    height: 130px;
                }   
                &:first-child{
                    button{
                        /* width: 100px;
                        height: 100px; */
                        position: absolute;
                        left: 8%;
                        top: -474px;
                        background-image: url(/assets/images/testimonial/client-1.jpeg);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 90%;
                        background-color: transparent;
                        border: none;
                        @media screen and (max-width: 900px){
                            left: 3%;
                            top: -483px;
                        }
                        @media screen and (max-width: 576px){
                            left: 8%;
                            top: -65px; 
                            background-size: 60%;  
                        }
                    }
                }
                &:nth-child(2){
                    button{
                        /* width: 100px;
                        height: 100px; */
                        position: absolute;
                        left: 7%;
                        top: -140px;
                        background-image: url(/assets/images/testimonial/client-2.png);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 90%;
                        background-color: transparent;
                        border: none;
                        @media screen and (max-width: 900px){
                            left: 2%;
                            top: -130px;
                        }
                        @media screen and (max-width: 576px){
                            background-size: 60%;
                            left: 12%;
                            top: 30px;
                        }
                    }
                }
                &:nth-child(3){
                    button{
                        /* width: 100px;
                        height: 100px; */
                        position: absolute;
                        right: 7%;
                        top: -405px;
                        top: -150px;
                        background-image: url(/assets/images/testimonial/client-3.jpeg);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 90%;
                        background-color: transparent;
                        border: none;
                        @media screen and (max-width: 900px){
                            right: 0%;
                            top: -130px;
                        }
                        @media screen and (max-width: 576px){
                            background-size: 50%;
                            right: 10%;
                            top: 30px;
                        }
                    }
                }
                // &:last-child{
                //     button{
                //         /* width: 100px;
                //         height: 100px; */
                //         position: absolute;
                //         right: 9%;
                //         top: -495px;
                //         background-image: url(/assets/images/testimonial/client1.jpeg);
                //         background-repeat: no-repeat;
                //         background-position: center;
                //         background-size: 90%;
                //         background-color: transparent;
                //         border: none;
                //         @media screen and (max-width: 576px){
                //             right: 10%;
                //             top: -65px; 
                //             background-size: 60%;  
                //         }
                //     }
                // }
            }
        }
    }
`;