import { FooterContainer, FooterBox, List } from './Footer-style';
import { BsFacebook, BsInstagram, BsYoutube, BsWechat } from "react-icons/bs";
import { AiFillLinkedin, AiFillTwitterCircle } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function Footer({ ishome }) {
    const { t } = useTranslation()
    return (
        <>

            <FooterContainer ishome={ishome}>

                <div className='container'>
                    <div className='row pt-5'>
                        <div className='col-xl-3 col-lg-3 col-md-3'>
                            <FooterBox>
                                <img className="footer-logo mb-3" src="/logo512.png" alt="Logo" />
                                <p>{t('productionHouse')}</p>
                            </FooterBox>
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6'>
                            <FooterBox>
                                <ul className='footer-list'>
                                    <List>
                                        <NavLink to="/visas" className="footer-link">{t('visaService')}</NavLink>
                                    </List>
                                    <List>
                                        <NavLink to="/legal" className="footer-link">{t('legalRegulation')}</NavLink>
                                    </List>
                                    <List>
                                        <NavLink to="/bank-account" className="footer-link">{t('bankingService')}</NavLink>
                                    </List>
                                    <List>
                                        <NavLink to="/real-estate" className="footer-link">{t('propertySaleRent')}</NavLink>
                                    </List>
                                    <List>
                                        <NavLink to="/company-reg" className="footer-link">{t('businessSetup')}</NavLink>
                                    </List>
                                    <List>
                                        <NavLink to="/car-service" className="footer-link">{t('vehicleService')}</NavLink>
                                    </List>
                                    <List>
                                        <NavLink to="/additional-service" className="footer-link">{t('paperWorkAssistance')}</NavLink>
                                    </List>
                                    <List>
                                        <NavLink to="/media" className="footer-link">{t('digitalMedia')}</NavLink>
                                    </List>

                                </ul>
                            </FooterBox>
                        </div>
                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6'>
                            <FooterBox>
                                <ul className='footer-list'>
                                    <List>
                                        <NavLink to="/about-us" className="footer-link">{t('aboutUs')}</NavLink>
                                    </List>
                                    {/* <List>
                                            <NavLink to="/portfolio" className="footer-link">Portfolio</NavLink>
                                        </List> */}
                                    <List>
                                        <NavLink to="/services" className="footer-link">{t('services')}</NavLink>
                                    </List>
                                    <List>
                                        <NavLink to="/testimonials" className="footer-link">{t('testimonials')}</NavLink>
                                    </List>
                                    {/* <List>
                                        <NavLink to="/training" className="footer-link">Trainings</NavLink>
                                    </List> */}
                                </ul>
                            </FooterBox>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-4'>
                            <FooterBox>
                                <ul className='footer-list address-list'>
                                    <List>
                                        {t('address')} : <span className='address'>5th Floor, Forum Tower, 184 Ratchadaphisek Rd, Khwaeng Huai Khwang, Huai Khwang, Bangkok 10310, Thailand</span>
                                    </List>
                                    <List>
                                        {t('email')} : <a href="mailto:info@mingtai.org">info@mingtai.org</a>
                                    </List>
                                    <List>
                                        {t('phoneNumber')}:  <a href="tel:+66 (0) 2-245-5634">+66 (0) 2-245-5634</a>

                                    </List>
                                    <List>
                                        {t('phoneNumber')}:  <a href="tel:+66 (0) 88-090-7555">+66 (0) 88-090-7555</a>
                                    </List>
                                    <List>
                                        {t('phoneNumber')}: <a href="tel:+66 (0) 61-951-6180">+66 (0) 61-951-6180</a>
                                    </List>
                                </ul>
                            </FooterBox>
                        </div>
                    </div>
                </div>
                <div className='copyright'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-xl-6 col-lg-6 col-md-6'>
                                <p className='unlock'>{t('unlockPotential')}</p>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6'>
                                <div className='footer-social'>
                                    <ul className='d-flex'>
                                        <li>
                                            <a href="https://m.facebook.com/people/Ming-Tai/100071655926962" target="_blank" rel="noopener noreferrer"><BsFacebook style={{ fontSize: "17px" }} /> Facebook</a>
                                        </li>
                                        {/* <li>
                                            <a href="#" target="_blank" rel="noopener noreferrer"><AiFillTwitterCircle style={{ fontSize: "18px" }} /> Twitter</a>
                                        </li> */}
                                        {/* <li>
                                            <a href="#" target="_blank" rel="noopener noreferrer"><BsInstagram style={{ fontSize: "17px" }} /> Instagram</a>
                                        </li> */}
                                        <li>
                                            <a href="https://www.youtube.com/@mingtai888" target="_blank" rel="noopener noreferrer"><BsYoutube style={{ fontSize: "18px" }} /> Youtube</a>

                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/in/admin-mingtai-a84297289" target="_blank" rel="noopener noreferrer"><AiFillLinkedin style={{ fontSize: "18px" }} /> Linkedin</a>
                                        </li>
                                        <li>
                                            <img width={100} height={140} src="assets/images/weChatMingtai.jpeg" alt="WeChat QR Code" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FooterContainer>

        </>
    )
}

