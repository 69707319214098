import styled from "styled-components";

export const TeamCardBox = styled.div`
   position: relative;
   text-align: center;
   border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    box-shadow: 0px 0px 15px 0px rgb(72 73 121 / 15%);
   .team-images {
        overflow: hidden;
        position: relative;
        transition: all 0.3s ease-in-out;
        box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 5%);
        .img-fluid{
            box-shadow: 0px 0px 15px 0px rgb(72 73 121 / 15%);
        }
        img{
            width: 100%;
            height: 420px;
            object-fit: cover;
        }
        .team-link {
            display: block;
            font-weight: 600;
            font-size: 24px;
            border-radius: 7px;
            color: #1c1d3e;
            background: #fff;
            height: 55px;
            width: 55px;
            line-height: 50px;
            opacity: 0;
            visibility: hidden;
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
            position: absolute;
            top: 2rem;
            right: 12px;
            z-index: 500;
            transition: all 0.4s ease-in-out;
            span{
                font-size: 20px;
                color: #1c1d3e;
            }
        }
        &:hover .team-link{
            visibility: visible;
            opacity: 1;
            right: 4.4rem;
            box-shadow: 0px 0px 9.31778px rgba(0, 0, 0, 0.1);
            color: #2d94ff;
        }

        &:hover .team-description{
            padding: 30px 20px 6px;
        }
    }
    .team-description {
        border-radius: 0px;
        margin-top: 0px;
        width: 100%;
        background: #fff;
        padding: 20px 20px 10px;
        text-align: center;
        z-index: 9;
        position: absolute;
        bottom: 0;
        background: #fff;
        text-align: center;
        transition: all 0.4s ease-in-out;
        span {
            color: #1c1d3e;
            font-weight: 400;
            font-size: 14px;
        }
        h5{
            color: #d53900;
            margin-top: 4px;
            font-size: 18px;
            font-weight: 600;
        }
    }
    &:hover .team-social-icon{
        visibility: visible;
        opacity: 1;
        top: 20px;
        left: 20px;
        /* box-shadow: 0px 0px 9.31778px rgba(0, 0, 0, 0.1); */
    }

`;

export const SocialIcon = styled.div`
    display: none;
     &.team-social-icon {
        position: absolute;
        top: 20px;
        left: -50px;
        z-index: 500;
        transition: all 0.3s ease-in-out;
        background-color: transparent;
    }

    ul{
        list-style: none;
        margin: 0px;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;

        li{
            padding: 0px !important;
            &:first-child{
                a{
                    background: #5D82D1;
                }
            }
            &:nth-child(2){
                a{
                    background: #40BFF5;
                }
            }
            &:nth-child(3){
                a{
                    background: #d6249f;
                    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
                }
            }
        
            &:last-child{
                a{
                    background: #238CC8;
                }
            }
           a{
            display: inline-block;
            height: 36px;
            width: 36px;
            line-height: 36px;
            font-size: 17px;
            text-align: center;
            margin: 0;
            border-radius: 50%;
            border: 0;
            background: transparent;
            color: #fff;
            overflow: hidden;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease; 
            &:hover{
                transform: rotateY(180deg);
            }
           } 
           
        }

    }
`;