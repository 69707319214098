import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FaGlobe, FaBullhorn, FaChartBar, FaUsers } from "react-icons/fa"; // Import icons
import Footer from "../../Footer/Footer";
import Navbar from "../../Header/Navbar/Navbar";
import ServicesBanner from "../ServicesBanner/ServicesBanner";

const ServicesContainer = styled.div`
  padding: 60px 0;
`;

const ServicesRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ServiceCard = styled.div`
  flex-basis: calc(50% - 20px);
  padding: 20px;
  background-color: #f5f7fa;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
  border: 1px solid #e1e5eb;

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
`;

const ServiceTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #2d3748;
`;

const ServiceText = styled.p`
  margin-bottom: 20px;
  color: #4a5568;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  svg {
    margin-right: 10px;
    color: #667eea;
  }
`;

export default function Media() {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <ServicesBanner
        deskbanner="assets/images/services/WechatIMG8.jpg"
        mobbanner="assets/images/services/WechatIMG8.jpg"
      />
      <ServicesContainer>
        <div className="container">
          <ServicesRow>
            <ServiceCard>
              <ServiceTitle>{t("media.internationalConferences.title")}</ServiceTitle>
              <ServiceText>{t("media.internationalConferences.description")}</ServiceText>
              <FeatureList>
                <FeatureItem>
                  <FaGlobe size={20} />
                  {t("media.internationalConferences.features.venueSelection")}
                </FeatureItem>
                <FeatureItem>
                  <FaGlobe size={20} />
                  {t("media.internationalConferences.features.logisticsManagement")}
                </FeatureItem>
                <FeatureItem>
                  <FaGlobe size={20} />
                  {t("media.internationalConferences.features.interpretationServices")}
                </FeatureItem>
                <FeatureItem>
                  <FaGlobe size={20} />
                  {t("media.internationalConferences.features.onSiteAssistance")}
                </FeatureItem>
              </FeatureList>
            </ServiceCard>
            <ServiceCard>
              <ServiceTitle>{t("media.pressConferences.title")}</ServiceTitle>
              <ServiceText>{t("media.pressConferences.description")}</ServiceText>
              <FeatureList>
                <FeatureItem>
                  <FaBullhorn size={20} />
                  {t("media.pressConferences.features.strategicMessaging")}
                </FeatureItem>
                <FeatureItem>
                  <FaBullhorn size={20} />
                  {t("media.pressConferences.features.eventPlanning")}
                </FeatureItem>
                <FeatureItem>
                  <FaBullhorn size={20} />
                  {t("media.pressConferences.features.mediaRelations")}
                </FeatureItem>
                <FeatureItem>
                  <FaBullhorn size={20} />
                  {t("media.pressConferences.features.crisisManagement")}
                </FeatureItem>
              </FeatureList>
            </ServiceCard>
          </ServicesRow>
          <ServicesRow>
            <ServiceCard>
              <ServiceTitle>{t("media.globalMediaPromotion.title")}</ServiceTitle>
              <ServiceText>{t("media.globalMediaPromotion.description")}</ServiceText>
              <FeatureList>
                <FeatureItem>
                  <FaChartBar size={20} />
                  {t("media.globalMediaPromotion.features.multiPlatformCampaigns")}
                </FeatureItem>
                <FeatureItem>
                  <FaChartBar size={20} />
                  {t("media.globalMediaPromotion.features.localizedContent")}
                </FeatureItem>
                <FeatureItem>
                  <FaChartBar size={20} />
                  {t("media.globalMediaPromotion.features.dataDrivenInsights")}
                </FeatureItem>
                <FeatureItem>
                  <FaChartBar size={20} />
                  {t("media.globalMediaPromotion.features.crossCulturalExpertise")}
                </FeatureItem>
              </FeatureList>
            </ServiceCard>
            <ServiceCard>
              <ServiceTitle>{t("media.onSiteAssistance.title")}</ServiceTitle>
              <ServiceText>{t("media.onSiteAssistance.description")}</ServiceText>
              <FeatureList>
                <FeatureItem>
                  <FaUsers size={20} />
                  {t("media.onSiteAssistance.features.eventCoordination")}
                </FeatureItem>
                <FeatureItem>
                  <FaUsers size={20} />
                  {t("media.onSiteAssistance.features.emergencyPreparedness")}
                </FeatureItem>
                <FeatureItem>
                  <FaUsers size={20} />
                  {t("media.onSiteAssistance.features.participantEngagement")}
                </FeatureItem>
                <FeatureItem>
                  <FaUsers size={20} />
                  {t("media.onSiteAssistance.features.personalizedSupport")}
                </FeatureItem>
              </FeatureList>
            </ServiceCard>
          </ServicesRow>
        </div>
      </ServicesContainer>
      <Footer ishome={false} />
    </>
  );
}
