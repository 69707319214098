import React from "react";
import Navbar from "../../Header/Navbar/Navbar";
import Banner from "../BannerComponent/Banner";
import styled from "styled-components";
import HeadingOne from "../Headings/HeadingOne";
import OurExpertTeam from "./OurExpertTeam";
import Newsletter from "../../NewsletterComponent/Newletter";
import Footer from "../../Footer/Footer";
import { useTranslation } from "react-i18next";

const AboutCard = styled.section`
  max-width: 100%;
  .ab-content-card {
    img {
      max-width: 150px;
    }

    h1 {
      font-family: 'Nunito', sans-serif;
    }

    p {
      text-align: justify;
    }
  }
`;

const AboutUs = () => {
  const { t} = useTranslation()
  return (
    <>
      <Navbar />

      <Banner
        title={t('aboutUs')}
        subtitle={t('companyHeader')}
        activelink="About Us"
        homeurl="/"
      />

      <AboutCard>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="about-img">
                <img src="assets/images/home/mission.jpeg" alt="About" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="ab-content-card">
                <HeadingOne title={t('companyHeader')} />
                <section>
                  <article>
                    <p>
                      {t('ourVision')}
                    </p>
                  </article>
                  <article>
                    <p>
                      {t('ourMission')}
                    </p>
                  </article>
                </section>
              </div>
            </div>
          </div>
        </div>
      </AboutCard>
      <OurExpertTeam />
      <Newsletter />
      <Footer ishome={false} />
    </>
  );
};

export default AboutUs;
