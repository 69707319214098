import React, { useEffect } from "react";
import Footer from "../../Footer/Footer";
import Navbar from "../../Header/Navbar/Navbar";
import HeadingH2 from "../../HeadingComponent/HeadingH2";
import ServicesBanner from "../ServicesBanner/ServicesBanner";
import { useTranslation } from "react-i18next";

export default function CompanyReg() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <>
      <Navbar />

      <ServicesBanner
        deskbanner="assets/images/services/WechatIMG5.jpg"
        mobbanner="assets/images/services/WechatIMG5.jpg"
      />

      <div className="services-card-content">
        <div className="container">
          <HeadingH2 title={t("companyReg.title")} />

          <h2 className="svtitle">{t("companyReg.expertsIn")}</h2>

          <p>{t("companyReg.expertsInDescription")}</p>

          <p>{t("companyReg.servicesDescription")}</p>

          <h2 className="svtitle">{t("companyReg.whyChooseUs")}</h2>

          <ul>
            <li>{t("companyReg.feature1")}</li>
            <li>{t("companyReg.feature2")}</li>
            <li>{t("companyReg.feature3")}</li>
            <li>{t("companyReg.feature4")}</li>
            <li>{t("companyReg.feature5")}</li>
          </ul>
        </div>
      </div>

      <Footer ishome={false} />
    </>
  );
}
