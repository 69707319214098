import styled from "styled-components";

export const TeamContainer = styled.div`
  width: 100%;
  .react-multi-carousel-list{
      padding: 30px 0px;
      .react-multi-carousel-item{
          padding: 12px;
      }
  }
`;

export const HeadTitle = styled.div`
    width: 100%;
    max-width: 1000px;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
    h1{
        max-width: 800px;
        margin: 20px auto;
    }
    h5{
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 5px;
        color: #1c1d3e;
    }
`;

export const ConsultContainer = styled.section`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 40px;
    padding: 60px 0px;
    background: #012fa7;
    position: relative;
    h2{
        color: #fff;
        font-size: 30px;
        max-width: 700px;
        margin: 10px auto 35px;
        @media screen and (max-width: 576px){
            font-size: 24px;
        }
    }
    .letstalk{
        padding: 10px 25px;
        border: none;
        background-color: #fff;
        border-radius: 5px;
        color: #000;
        font-size: 15px;
        margin-top: 15px;
        transition: all 0.4s ease-in-out;
        text-decoration: none;
        
        &:hover{
            background-color: #f5f5f5;
        }
    }

    .alineanim{
        position: absolute;
        left: 12%;
        top: 25px;
        animation: mymove 8s infinite;
        animation-direction: alternate;
        @media screen and (max-width: 576px){
            top: 15px;
        }
        img{
            width: 80px;
            @media screen and (max-width: 576px){
                width: 60px;
            }
        }
    }
    @keyframes mymove {
        from {left: 20px;}
        to {left: 200px;}
        }
    .alineanim1{
        position: absolute;
        right: 10%;
        bottom: 30px;
        animation: mymove1 8s infinite;
        animation-direction: alternate;
        @media screen and (max-width: 576px){
            bottom: 15px;
        }
        img{
            width: 80px;
            @media screen and (max-width: 576px){
                width: 60px;
            }
        }
       
        }
        @keyframes mymove1 {
        from {right: 20px;}
        to {right: 200px;}
        }
`;



export const MessageCEO = styled.section`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 40px;
    padding: 60px 0px;
    background: #E7AB79;
    position: relative;
    h1{
        font: 600;
        font-family: Roboto;
        color: #fff;
        font-size: 30px;
        max-width: 700px;
        margin: 10px auto 35px;
        text-decoration: underline;
        @media screen and (max-width: 576px){
            font-size: 24px;
        }
    }
    h2{
        font: 600;
        font-family: Roboto;
        color: #fff;
        font-size: 30px;
        max-width: 700px;
        margin: 10px auto 35px;
        @media screen and (max-width: 576px){
            font-size: 24px;
        }
    }
   
    .alineanim{
  
        @media screen and (max-width: 576px){
            top: 15px;
        }
        img{
                
            width: 200px;
            border-radius: 6px;
            @media screen and (max-width: 576px){
                width: 60px;
            }
        }
    }
    @keyframes mymove {
        from {left: 20px;}
        to {left: 200px;}
        }
`;