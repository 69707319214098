import React, { useEffect } from "react";
import Footer from "../../Footer/Footer";
import Navbar from "../../Header/Navbar/Navbar";
import HeadingH2 from "../../HeadingComponent/HeadingH2";
import HeadingH3 from "../../HeadingComponent/HeadingH3";
import ServicesBanner from "../ServicesBanner/ServicesBanner";
import { useTranslation } from "react-i18next";

export default function Services() {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <>
            <Navbar />
            <ServicesBanner
                deskbanner="assets/images/services/WechatIMG3.jpg"
                mobbanner="assets/images/services/WechatIMG3.jpg"
            />
            <div className="services-card-content">
                <div className="container">
                    <HeadingH2 title={t("additionalCard.title")} />
                    <p>{t("additionalCard.assistingCustomers")}</p>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <ul>
                                <li>{t("additionalCard.localCards")}</li>
                                <li>{t("additionalCard.thaiSimCards")}</li>
                                <li>{t("additionalCard.creditCards")}</li>
                                <li>{t("additionalCard.transportationCards")}</li>
                                <li>{t("additionalCard.facilitateActivities")}</li>
                            </ul>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <img
                                className="container"
                                src="/assets/images/cards.jpeg"
                                alt="benefits"
                            />
                        </div>
                    </div>
                    {/* Driving License Services */}
                    <HeadingH2 title={t("drivingLicenseService.title")} />

                    <h2 className="svtitle">{t("drivingLicenseService.description")}</h2>

                    <HeadingH3 title={t("drivingLicenseService.serviceDescription")} />

                    <p>{t("drivingLicenseService.serviceDescription")}</p>

                    <h2 className="svtitle">{t("drivingLicenseService.whyChooseUs")}</h2>

                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <ul>
                                <li>{t("drivingLicenseService.feature1")}</li>
                                <li>{t("drivingLicenseService.feature2")}</li>
                                <li>{t("drivingLicenseService.feature3")}</li>
                                <li>{t("drivingLicenseService.feature4")}</li>
                                <li>{t("drivingLicenseService.feature5")}</li>
                                <li>{t("drivingLicenseService.feature6")}</li>
                            </ul>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <img
                                className="container"
                                src="/assets/images/driving-license.png"
                                alt="benefits"
                            />
                        </div>
                    </div>

                    {/* Bank Account Services */}
                    <HeadingH2 title={t("bankAccountService.title")} />

                    <h2 className="svtitle">{t("bankAccountService.description")}</h2>

                    <p>{t("bankAccountService.serviceDescription")}</p>

                    <h2 className="svtitle">{t("bankAccountService.whyChooseUs")}</h2>

                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <ul>
                                <li>{t("bankAccountService.feature1")}</li>
                                <li>{t("bankAccountService.feature2")}</li>
                                <li>{t("bankAccountService.feature3")}</li>
                                <li>{t("bankAccountService.feature4")}</li>
                                <li>{t("bankAccountService.feature5")}</li>
                            </ul>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <img
                                className="container"
                                src="/assets/images/banking.png"
                                alt="benefits"
                            />
                        </div>
                    </div>

                </div >
            </div>
            <Footer ishome={false} />
        </>
    );
}
