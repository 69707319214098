import { useEffect, useState } from "react";
import { MainContainer } from "./Navbar-style";
import { NavLink, useLocation } from "react-router-dom";
import ScrollToTop from "../../ScrollToTop";
import { useTranslation } from 'react-i18next';
import i18n from '../../../utils/i18n';

export default function Navbar() {
  const location = useLocation();
  const [scroll, setScroll] = useState(false);
  const { t } = useTranslation();
  const languageOptions = [
    { code: 'en', flag: 'flag-en.svg' },
    { code: 'th', flag: 'flag-th.svg' },
    { code: 'zh', flag: 'flag-zh.svg' }
  ];
  const [isActive, setIsActive] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [selectedLanguageFlag, setSelectedLanguageFlag] = useState('flag-en.svg'); // Initialize with a default flag

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 120);
    });
  }, []);

  useEffect(() => {
    // Update selectedLanguageFlag whenever selectedLanguage changes
    const newFlag = languageOptions.find(option => option.code === selectedLanguage)?.flag;
    setSelectedLanguageFlag(newFlag);
  }, [selectedLanguage]);

  const handleLanguageSelect = (language) => {
    // Change the language using i18n.changeLanguage
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
    setIsActive(false);
  };

  const handleClick = event => {
    event.currentTarget.classList.toggle('toggle-dropdown');
  };

  return (
    <>
      <MainContainer>
        <ScrollToTop />
        <nav className={scroll ? "navbar navbar-expand-sm bg-light navbar-light fixed" : "navbar navbar-expand-sm bg-light navbar-light"} >
          <div className="container-fluid">

            <NavLink to="/" className="navbar-brand">
              <img className="dardk-logo" src="logo512.png" alt="Logo" />
            </NavLink>
            {/* Language Menu */}
            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle ln"
                type="button"
                id="languageDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {/* Placeholder span for accessibility */}
                <span className="sr-only">Selected Language</span>
                <img
                  width={23}
                  height={15}
                  src={`assets/images/${selectedLanguageFlag}`} // Replace with the appropriate variable
                  alt={selectedLanguage}
                  className="flag-icon"
                />
              </button>
              <div className="dropdown-menu" aria-labelledby="languageDropdown">
                {languageOptions.map((option) => (
                  <p key={option.code} onClick={() => handleLanguageSelect(option.code)}>
                    <button className="dropdown-item" type="button">
                      <img
                        width={23}
                        height={15}
                        src={`assets/images/${option.flag}`}
                        alt={option.code}
                        className="flag-icon"
                      />
                      <span className="sr-only">{t(option.code)}</span>
                    </button>
                  </p>
                ))}
              </div>
            </div>

            {/* <button
              className="navbar-toggler"
              type="button"
              onClick={() => setIsActive((current) => !current)}
            >
              <span className="navbar-toggler-icon" />
            </button> */}
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse justify-content-end" id="collapsibleNavbar">
              <ul className="navbar-nav">
              <li className="nav-item">
                  <NavLink to="/" className={location.pathname === '/' ? "nav-link isactive" : "nav-link"}>{t('home')}</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/about-us" className={location.pathname === '/about-us' ? "nav-link isactive" : "nav-link"}>{t('aboutUs')}</NavLink>
                </li>
                <li className="nav-item dropdown">
                  <NavLink to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">{t('services')}</NavLink>
                  <ul className={`dropdown-menu ${isActive ? 'show' : ''}`}>
                    <li className="dropdown sub-dropdown" onClick={handleClick}>
                      <NavLink to="/visas" className="dropdown-item">
                        {t('visaService')}
                      </NavLink>
                    </li>
                    <li className="dropdown sub-dropdown" onClick={handleClick}>
                      <NavLink to="/legal" className="dropdown-item">
                        {t('legalRegulation')}
                      </NavLink>
                    </li>
                    <li className="dropdown sub-dropdown" onClick={handleClick}>
                      <NavLink to="/bank-account" className="dropdown-item">
                        {t('bankingService')}
                      </NavLink>
                    </li>
                    <li className="dropdown sub-dropdown" onClick={handleClick}>
                      <NavLink to="/real-estate" className="dropdown-item">
                        {t('propertySaleRent')}
                      </NavLink>
                    </li>
                    <li className="dropdown sub-dropdown" onClick={handleClick}>
                      <NavLink to="/company-reg" className="dropdown-item">
                        {t('businessSetup')}
                      </NavLink>
                    </li>
                    <li className="dropdown sub-dropdown" onClick={handleClick}>
                      <NavLink to="/car-service" className="dropdown-item">
                        {t('vehicleService')}
                      </NavLink>
                    </li>
                    <li className="dropdown sub-dropdown" onClick={handleClick}>
                      <NavLink to="/additional-service" className="dropdown-item">
                        {t('paperWorkAssistance')}
                      </NavLink>
                    </li>
                    <li className="dropdown sub-dropdown" onClick={handleClick}>
                      <NavLink to="/media" className="dropdown-item">
                        {t('digitalMedia')}
                      </NavLink>
                    </li>
                    <li className="dropdown sub-dropdown" onClick={handleClick}>
                      <NavLink to="/tech" className="dropdown-item">
                        {t('webAndMobileDevelopment.title')}
                      </NavLink>
                    </li>
                    </ul>
                </li>

                <li className="nav-item">
                  <NavLink to="/our-team" className={location.pathname === '/our-team' ? "nav-link isactive" : "nav-link"}>{t('ourTeam')}</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/testimonials" className={location.pathname === '/testimonials' ? "nav-link isactive" : "nav-link"}>  {t('testimonials')}</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/contact-us" className="nav-link conactbtn">
                    {t('contactUs')}
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>

      </MainContainer>
    </>
  )
}
