import styled from "styled-components";

export const ClientCardsBox = styled.div`
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     margin-top: 30px;
     padding: 15px;

     .clients-img{
         margin-bottom: 20px;
         position: relative;
         border-radius: 50%;
         
         img{
             width: 200px;
             border-radius: 50%;
             animation: zoom-in-zoom-out 5s ease infinite;
             @media screen and (max-width: 860px){
                width: 160px;   
             }
             @media screen and (max-width: 576px){
                width: 130px;
            }
         }
         @keyframes zoom-in-zoom-out {
            0% {
                transform: scale(1, 1);
            }
            50% {
                transform: scale(1.1);
            }
            100% {
                transform: scale(1, 1);
            }
        }
     }

   .client-contents{
     max-width: 800px;
     margin:25px auto;
     background: #FFFFFF;
     box-shadow: 0px 0px 10px rgb(0 0 0 / 12%);
     border-radius: 5px;
     padding: 20px;
     display: flex;
     flex-direction: column;
     p{
        color: #1c1d3e;
        font-size: 1.1rem;
        line-height: 28px;
        font-weight: 500;
        @media screen and (max-width: 900px){
            font-size: 1rem;
            line-height: 25px;
        }
        @media screen and (max-width: 576px){
            font-size: 0.9rem;
            line-height: 25px;
        }
         span{
             color: #008FFF;
             font-weight: 700;
         }
     }
   }
   .testimonial-content{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        padding-top: 10px;
        position: relative;
        &:before {
            content: "";
            background: #fff;
            width: 100%;
            height: 100px;
            position: absolute;
            left: 0px;
            bottom: -39px;
            z-index: -1;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
            border-radius: 5px;
            @media screen and (max-width: 576px){
                bottom: -35px;
            }
        }
        &:after {
            content: "";
            background: #fff;
            width: 96%;
            height: 100px;
            position: absolute;
            left: 17px;
            bottom: -57px;
            z-index: -2;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
            border-radius: 5px;
            @media screen and (max-width: 576px){
                width: 90%;
                left: 15px;
                bottom: -49px;
            }
        }
    .testimonial-caption{
        position: relative;
        margin-top: 10px;
        display: inline-block;
       h5{
           font-size: 1.4rem;
           font-family: 'Ubuntu';
           line-height: 28px;
           font-weight: 700;
           color: #012fa7;
           margin-top: 20px;
           position: relative;
           &:before{
            content: "";
            background: #012fa7;
            border-radius: 10px;
            width: 80%;
            height: 4px;
            position: absolute;
            left: 0%;
            top: -20px;
            z-index: 1; 
           }
           &:after{
            content: "";
            background: #012fa7;
            border-radius: 10px;
            width: 14%;
            height: 4px;
            position: absolute;
            right: 0%;
            top: -20px;
            z-index: 1; 
           }
       }
       label{
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #1c1d3e;
       }
   }
   }
  
`;