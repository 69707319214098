import styled from "styled-components";

export const FooterContainer = styled.footer`
    width: 100%;
    background-image: ${({ ishome }) => ishome ? 'url(../assets/images/bg/footer-bg.png)' : "url(../assets/images/bg/footer-bg-1.png)"};
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    padding-top: 30px;
    padding-bottom: 30px;
    .main-footer{
        @media screen and (max-width: 576px) {
        background-size: 0%;
        background-color: #f3f5ff;
     }   
    }
    .unlock {
        text-decoration: none;
                   font-weight: 600;
                   color: white;
                   display: inline-block;
    }
    

    .footer-social{
        padding-bottom: 10px;
       ul{
           margin: 0px;
           padding: 0px;
           justify-content: flex-end;
           gap: 6px;
           @media screen and (max-width: 576px){
            gap: 0px;
            justify-content: center;
           }
           li{
               list-style: none;
               font-size: 15px;
               a{
                   text-decoration: none;
                   font-weight: 600;
                   color: white;
                   display: inline-block;
                    padding: 4px;
                    transition: all 0.3s ease-in-out;
                    &:hover{
                        color: #2575fc;
                    }
                    @media screen and (max-width: 576px){
                        padding: 3px;
                        text-align: center;
                        font-weight: 600;
                        color: white;
                    }
               }
             
           }
       }
   }
`;

export const FooterBox = styled.div`
   width: 100%;
   margin-bottom: 30px;
   @media screen and (max-width: 576px){
    margin-bottom: 20px;
   }
   .footer-logo{
       width: 150px;
       
       @media screen and (max-width: 576px){
          margin-top: -25px;
          margin-left: -8px;
       }
   }
   p{
    font-weight: 600;
    color: white;
        @media screen and (max-width: 576px){
            font-size: 14px;
        }
   }
   .address{

       max-width: 250px;
       font-weight: 600;
       color: white;
       @media screen and (max-width: 576px){
        max-width: 230px;
       }
   }
   .email{
        font-weight: 600;
                   color: white;
   }
   .mob-num{
    font-weight: 600;
    color: white;
   }

   .footer-list{
       margin: 0px;
       padding: 0px;
       &.address-list{
           a{
            font-weight: 600;
            color: white;
               font-weight: 600;
           }
       }
       
   }
`;

export const List = styled.li`
    list-style: none;
    display: flex;
    font-size: 15px;
    column-gap: 10px;
    margin-bottom: 8px;
    font-weight: 400;
    font-weight: 600;
            color: white;
    @media screen and (max-width: 576px){
        font-size: 14px;
        margin-bottom: 5px;
    }
    a{
        text-decoration: none;
        font-weight: 600;
            color: white;

        transition: all 0.4s ease-in-out;
       &:hover{
        color: #2575fc;
        text-decoration: underline;
       } 
    }
    
`;

export const Link = styled.a`
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #1E2A3A;
    text-decoration: none;
`;