import { ClientCardsBox } from "./ClientCards-style";


export default function ClientCards(props){
    return(
        <>
          <ClientCardsBox>
               <div className="clients-img">
                   <img src={ props.image } /> 
               </div>
               <div className="client-contents">
                    <div className="testimonial-content">
                        <p>{props.comments}</p>    
                        <div className="testimonial-caption">
                            <h5>{ props.name }</h5>
                            <label>{props.position}</label>
                        </div>   
                    </div>
               </div>
          </ClientCardsBox>
        </>
    )
}