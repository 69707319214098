import styled from "styled-components";

export const TeamCardBox = styled.div`
   position: relative;
   text-align: center;
    border-radius: 10px;
   .team-images {
        overflow: hidden;
        position: relative;
        transition: all 0.3s ease-in-out;
        box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 5%);
        .img-fluid{
            width: 100%;
            height: 420px;
            object-fit: cover;
            box-shadow: 0px 0px 15px 0px rgb(72 73 121 / 15%);
            @media screen and (min-width: 1680px) and (max-width: 2000px){
                max-height: 600px;
            }
            @media screen and (max-width: 1460px) {
                max-height: 440px;
            }
            @media screen and (max-width: 1360px) {
                max-height: 400px;
            }
            @media screen and (max-width: 576px) {
                max-height: 350px;
            }
        }
        .team-link {
            display: block;
            font-weight: 600;
            font-size: 30px;
            border-radius: 7px;
            color: #1c1d3e;
            background: #fff;
            height: 55px;
            width: 55px;
            line-height: 42px;
            opacity: 0;
            visibility: hidden;
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
            position: absolute;
            top: 2rem;
            right: 1rem;
            z-index: 500;
            transition: all 0.4s ease-in-out;
            span{
                font-size: 20px;
            }
        }
        &:hover .team-link{
            visibility: visible;
            opacity: 1;
            right: 4.4rem;
            box-shadow: 0px 0px 9.31778px rgba(0, 0, 0, 0.1);
        }
    }
    .team-description {
        border-radius: 7px;
        width: 86%;
        margin: -35px auto 0px;
        background: #fff;
        padding: 15px;
        text-align: center;
        /* width: 100%; */
        position: relative;
        z-index: 9;
        box-shadow: 0px 0px 15px 0px rgb(72 73 121 / 15%);
         span {
            color: #1c1d3e;
            font-weight: 400;
            font-size: 14px;
        }
        h5{
            color: #012fa7;
            margin-top: 4px;
            font-size: 1.2rem;
        }
    }
    .team-member.style-2 .team-social-icon {
        position: inherit;
        -webkit-transform: inherit;
        transform: inherit;
        opacity: 1;
        left: inherit;
        top: inherit;
        visibility: visible;
        margin-top: 15px;
    }
`;

export const SocialIcon = styled.div`
    margin-top: 15px;
    display: none;
     &.team-social-icon {
        position: inherit;
        -webkit-transform: inherit;
        transform: inherit;
        opacity: 1;
        left: inherit;
        top: inherit;
        visibility: visible;
    }

    ul{
        list-style: none;
        margin: 0px;
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;

        li{
            padding: 0px !important;
            &:first-child{
                a{
                    background: #5D82D1;
                }
            }
            &:nth-child(2){
                a{
                    background: #40BFF5;
                }
            }
            &:nth-child(3){
                a{
                    background: #d6249f;
                    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
                }
            }
        
            &:last-child{
                a{
                    background: #238CC8;
                }
            }
           a{
            display: inline-block;
            height: 36px;
            width: 36px;
            line-height: 35px;
            font-size: 17px;
            text-align: center;
            margin: 0;
            border-radius: 50%;
            border: 0;
            background: transparent;
            color: #fff;
            overflow: hidden;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease; 
            &:hover{
                transform: rotateY(180deg);
            }
           } 
           
        }

    }
`;