import styled from "styled-components";

export const CardBox = styled.div`
    width: 100%;
    height: 350px;
    img{
        height: auto;
    }

    h4{
        font-size: 15px;
        font-weight: 500;
        margin: 18px 0px 15px;
    }
`;

