import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import Footer from "../../Footer/Footer";
import Navbar from "../../Header/Navbar/Navbar";
import HeadingH2 from "../../HeadingComponent/HeadingH2";
import ServicesBanner from "../ServicesBanner/ServicesBanner";
import SuccessStories from "../../Home/SuccessStories/SuccessStories";

export default function Visas() {
    const { t } = useTranslation();

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
    
    return (
        <>
            <Navbar />
            <ServicesBanner deskbanner="assets/images/services/WechatIMG1.jpg" mobbanner="assets/images/services/WechatIMG1.jpg" />
            <div className="services-card-content">
                <div className="container">
                    <HeadingH2 title={t('visaServices.title')} />
                    <p>
                        {t('visaServices.description')}
                    </p>

                    <ul>
                        <li>
                            {t('visaServices.visaTypes.education')}
                        </li>
                        <li>
                            {t('visaServices.visaTypes.nonB')}
                        </li>
                        <li>
                            {t('visaServices.visaTypes.elite')}
                        </li>
                        <li>
                            {t('visaServices.visaTypes.retirement')}
                        </li>
                        <li>
                            {t('visaServices.visaTypes.overstay')}
                        </li>

                        <li>
                            {t('visaServices.visaTypes.nonO')}
                        </li>
                        <li>
                            {t('visaServices.visaTypes.smart')}
                        </li>
                    </ul>
                </div>
            </div>
            <SuccessStories />
            <Footer ishome={false} />

        </>
    )
}
