import TeamExpertCards from "./ExpertTeamCards";
import { OurTeamContainer } from "./OurExpertTeam-style";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import HeadingThree from "../Headings/HeadingThree";
import { useTranslation } from "react-i18next";


export default function OurExpertTeam() {
  const { t } = useTranslation()
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <>
      <OurTeamContainer>
        <div className="container">
          <h5>{t('creativeTeam')}</h5>
          <HeadingThree title={t('expertTeam')} />
          <div className="row justify-content-end">
            <div className="col-xl-11 col-lg-11 col-md-12">
              <Carousel className="" responsive={responsive} autoPlay infinite={true} autoPlaySpeed={5000} arrows={false} swipeable={true}>
                <div><TeamExpertCards image="assets/images/team/2.jpeg" title="Lilian Liu" designation="Executive Director" /></div>
                <div><TeamExpertCards image="assets/images/team/3.jpeg" title="Lucien Liu" designation="Financial Director" /></div>
                <div><TeamExpertCards image="assets/images/team/4.jpeg" title="Nic" designation="Transport Manager" /></div>
                <div><TeamExpertCards image="assets/images/team/5.jpeg" title="Rui Li" designation="Global Visa Manager" /></div>
                <div><TeamExpertCards image="assets/images/team/6.jpeg" title="May Li" designation="Key Account Manager" /></div>
                <div><TeamExpertCards image="assets/images/team/7.jpeg" title="Lily" designation="Legal Director" /></div>
                <div><TeamExpertCards image="assets/images/team/8.jpeg" title="Yangyang" designation="Director of Transportation" /></div>
                <div><TeamExpertCards image="assets/images/team/9.jpeg" title="Jinmei Duan" designation="Visa Department" /></div>
                <div><TeamExpertCards image="assets/images/team/10.jpeg" title="Joey Zhang" designation="Legal Specialist" /></div>
                <div><TeamExpertCards image="assets/images/team/11.jpeg" title="Jokoe" designation="Transport Driver" /></div>
                <div><TeamExpertCards image="assets/images/team/12.jpeg" title="Qilin Wang" designation="Chief Assistant" /></div>
              </Carousel>
            </div>
          </div>
        </div>
      </OurTeamContainer>
    </>
  )
}