import styled from "styled-components";

export const ContactContainer = styled.section`

    .map{
        z-index: -1;
    }
`;


export const ContactForm = styled.div`
    width: 100%;
    margin-top: 50px;
    input{
        height: 42px;
        @media screen and (max-width: 576px) {
            height: 40px;
        }
    }
    label{
        font-size: 0.875rem;
    }
    .form-control{
        color: #212529;
        border-color: #ddd;
        font-size: 14px;
        &::placeholder{
            color: #545556;
        }
    }
    .sendmsg{
        background: #012fa7;
        border-radius: 30px;
        border-color: #012fa7;
        font-weight: 500;
        font-size: 15px;
        padding: 10px 25px;
        border: none;
        margin-top: 10px;
        transition: all 0.4s ease-in-out;
        &:hover{
            transform: translateY(-4px);
        }
    }
`;

export const ContactDetails = styled.div`  
    width: 100%;
    margin-top: 60px;
    margin-bottom: -50px;
    z-index: 100;
    position: relative;
    padding: 0px 40px;
    @media screen and (max-width: 576px){
        padding: 0px 10px;
    }
    .contact-card{
        min-height: 180px;
        padding: 20px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px; 
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        text-align: center;
        border-bottom: 3px solid #0d6efd;
        @media screen and (max-width: 576px) {
            margin-bottom: 30px;
        }

        h5{
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 0px;
        }
        p{
            margin-bottom: 0px;
            a{
                color: #2575FC;
                text-decoration: none;
                &:hover{
                    color: #008FFF;
                }
            }
        }
    }
`;

