import styled from 'styled-components';

export const BannerContainer = styled.div`
    max-width: 100%;
    height: auto;
    background-image: url('/assets/images/bg/bg.jpeg');
    background-repeat: no-repeat;
    background-position: top 100% center;
    background-size: cover;
    padding-top: 70px;
    margin-top: 125px;
    @media screen and (max-width: 576px){
        height: auto;
        padding-top: 70px;
        background-position: top 0% left 0%;
        background-image: url('/assets/images/bg/bg.png');
    }
    .cl {
        background: #012fa7;
        box-sizing: content-box;
        text-align: center;
        justify-content: center;
        color: white;
        font-family: Roboto;
        font-weight: 600;
        border-radius: 20px;
        opacity: 0.8;
        padding: 10px;

    }
    .banner-img{
        padding-left: 20px;
        @media screen and (max-width: 576px){
            width: 50%;
            height: auto;
            text-align: center;
            padding-left: 0px;
        }
        img{
            width: 85%;
        }
    }
  
    .banner-content{
        padding-left: 50px;
        @media screen and (max-width: 576px){
            margin-top: 50px;
            padding-left: 0px !important;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
            h3{
                font-size: 3rem;
                color: white;
                font-family: 'Ubuntu';
                font-weight: 380;
                margin-bottom: 20px;
                @media screen and (max-width: 576px){
                    font-size: 1.8rem;
                }
                span{
                    color: #012fa7;
                    font-weight: 700;
                    text-transform: capitalize;
                }
            }
        }
        
    }
`;


export const ButtonCard = styled.div`
        margin-top: 32px;
        gap: 20px;
        z-index: 99;
        @media screen and (max-width: 576px){
            margin-top: 5px;
        }
        .btn{
           transition: all 0.4s ease-in-out;
           position: relative;
           &:hover{
                transform: translateY(-3px);
           }
        }

       .contactBtn{
            padding: 10px 22px;
            background: #012fa7;
            border: none;
            color: #fff;
            height: 46px;
            border-radius: 7px;
            font-weight: 500;
            font-size: 16px;
            @media screen and (max-width: 576px){
                height: 42px;
                font-size: 14px;
                padding: 6px 15px;
            }
       }

       .consultBtn{
            border: none;
            color: #fff;
            padding: 10px 22px;
            height: 46px;
            border-radius: 7px;
            font-weight: 500;
            font-size: 16px;
            background-color: #000000;
            @media screen and (max-width: 576px){
                height: 42px;
                font-size: 14px;
                padding: 6px 15px;
            }
       }
`;