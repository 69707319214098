import React from "react";
import Footer from "../../Footer/Footer";
import Navbar from "../../Header/Navbar/Navbar";
import HeadingH2 from "../../HeadingComponent/HeadingH2";
import ServicesBanner from "../ServicesBanner/ServicesBanner";
import SuccessStories from "../../Home/SuccessStories/SuccessStories";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const AdditionalServiceContainer = styled.div`
  .subtitle {
    font-weight: bold;
    color: #333; /* Change color as needed */
  }

  .intro {
    font-size: 18px;
    color: #777; /* Change color as needed */
    margin-bottom: 20px;
  }

  .section-heading {
    font-size: 24px;
    margin-top: 40px;
    margin-bottom: 20px;
    color: #333; /* Change color as needed */
  }

  .service-item {
    margin-bottom: 40px;

    .service-heading {
      font-size: 20px;
      margin-bottom: 10px;
      color: #333; /* Change color as needed */
    }

    .service-description {
      font-size: 16px;
      color: #666;
    }
  }

  .feature-list {
    margin-top: 30px;

    .feature-heading {
      font-size: 22px;
      margin-bottom: 10px;
      color: #333; /* Change color as needed */
    }

    .feature-description {
      font-size: 16px;
      color: #666;
      margin-bottom: 15px;
    }
  }

  .discover-convenience {
    font-size: 18px;
    color: #777; /* Change color as needed */
    margin-top: 20px;
  }

  .icon {
    font-size: 24px;
    margin-right: 10px;
    color: #0dcaf0;
  }
`;

export default function AdditionalService() {
  const { t } = useTranslation();

  return (
    <AdditionalServiceContainer>
      <Navbar />

      <ServicesBanner
        deskbanner="assets/images/services/WechatIMG7.jpg"
        mobbanner="assets/images/services/WechatIMG7.jpg"
      />

      <div className="services-card-content">
        <div className="container">
          <HeadingH2 title={t("additionalService.title")} />
          <p className="subtitle">{t("additionalService.subTitle")}</p>
          <p className="intro">{t("additionalService.intro")}</p>

          <div className="additional-services">
            <h2 className="section-heading">{t("additionalService.ourServices")}</h2>
            <div className="service-item">
              <h3 className="service-heading">
                <FontAwesomeIcon icon={faCheckCircle} className="icon" />
                {t("additionalService.addressVerification")}
              </h3>
              <p className="service-description">{t("additionalService.addressVerificationDescription")}</p>
            </div>
            <div className="service-item">
              <h3 className="service-heading">
                <FontAwesomeIcon icon={faCheckCircle} className="icon" />
                {t("additionalService.checkCashing")}
              </h3>
              <p className="service-description">{t("additionalService.checkCashingDescription")}</p>
            </div>
            <div className="service-item">
              <h3 className="service-heading">
                <FontAwesomeIcon icon={faCheckCircle} className="icon" />
                {t("additionalService.foreignExchange")}
              </h3>
              <p className="service-description">{t("additionalService.foreignExchangeDescription")}</p>
            </div>
          </div>

          <h2 className="section-heading">{t("additionalService.whyChooseUs")}</h2>
          <div className="feature-list">
            <h3 className="feature-heading">
              <FontAwesomeIcon icon={faCheckCircle} className="icon" />
              {t("additionalService.experience")}
            </h3>
            <p className="feature-description">{t("additionalService.experienceDescription")}</p>

            <h3 className="feature-heading">
              <FontAwesomeIcon icon={faCheckCircle} className="icon" />
              {t("additionalService.customizedSolutions")}
            </h3>
            <p className="feature-description">{t("additionalService.customizedSolutionsDescription")}</p>

            <h3 className="feature-heading">
              <FontAwesomeIcon icon={faCheckCircle} className="icon" />
              {t("additionalService.efficiency")}
            </h3>
            <p className="feature-description">{t("additionalService.efficiencyDescription")}</p>

            <h3 className="feature-heading">
              <FontAwesomeIcon icon={faCheckCircle} className="icon" />
              {t("additionalService.professionalSupport")}
            </h3>
            <p className="feature-description">{t("additionalService.professionalSupportDescription")}</p>

            <h3 className="feature-heading">
              <FontAwesomeIcon icon={faCheckCircle} className="icon" />
              {t("additionalService.successStories")}
            </h3>
            <p className="feature-description">{t("additionalService.successStoriesDescription")}</p>
          </div>

          <p className="discover-convenience">{t("additionalService.discoverConvenience")}</p>
        </div>
      </div>

      <SuccessStories />

      <Footer ishome={false} />
    </AdditionalServiceContainer>
  );
}
