import styled from "styled-components";

const ServiceBannerContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
  background-image: url('/assets/images/home/bg.png');
  background-repeat: no-repeat;
  background-position: top 100% left 0;
  background-size: cover;
  // border-color:#0dcaf0;
  padding-top: 90px;

  @media screen and (max-width: 576px) {
    height: 460px;
    padding-top: 60px;
    background-position: top 0 left 0;
  }

  .desktop-banner {
    @media screen and (max-width: 576px) {
      display: none;
    }
  }

  .mobile-banner {
    display: none;
    @media screen and (max-width: 576px) {
      display: block;
    }
  }

  .cards-ban {
    width: 100%;
    margin-top: 100px;
    padding: 10px;
    max-width: 1000px;
    margin: 0 auto;

    @media screen and (max-width: 576px) {
      width: 100%;
      margin: 30px auto;
    }

    img {
      width: 100%;
      height: 450px;
      object-fit: cover;
      border-radius: 10px;
      box-shadow: 0 15px 10px  #1b2fa7;
      border: 3px solid #fff;

      @media screen and (max-width: 576px) {
        height: 250px;
        border: 3px solid #fff;
      }
    }
  }
`;

export default function ServicesBanner(props) {
  return (
    <ServiceBannerContainer>
      <div className="container-fluid">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-12">
            <div className="cards-ban">
              <img className="desktop-banner" src={props.deskbanner} alt="Banner" />
              <img className="mobile-banner" src={props.mobbanner} alt="Banner" />
            </div>
          </div>
        </div>
      </div>
    </ServiceBannerContainer>
  );
}
