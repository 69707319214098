import React from 'react';
import { MainContainer } from './Testimonials-style';
import HeadingH3 from '../../HeadingComponent/HeadingH3';
import { BiComment } from 'react-icons/bi';
import ClientCards from './ClientCards';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useTranslation } from 'react-i18next';

export default function Testimonials() {
  const { t } = useTranslation();

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 1 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
  };

  return (
    <MainContainer className="Testimonials">
      <div className="container">
        <div className="titel-head d-flex justify-content-center align-items-center">
          <div className="title-effect title-effect-2">
            <div className="ellipse"></div> <span><BiComment /></span>
          </div>
          <HeadingH3 title={t('clientsFeedback')} />
        </div>

        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-8">
            <Carousel responsive={responsive} autoPlay infinite autoPlaySpeed={3000} showDots arrows={false}>
              <ClientCards image="/assets/images/testimonial/client-1.jpeg" name={t('review.client1Name')} position={t('review.client1Position')} comments={t('review.client1Comments')} />
              <ClientCards image="/assets/images/testimonial/client-2.png" name={t('review.client2Name')} position={t('review.client2Position')} comments={t('review.client2Comments')} />
              <ClientCards image="/assets/images/testimonial/client-3.jpeg" name={t('review.client3Name')} position={t('review.client3Position')} comments={t('review.client3Comments')} />
            </Carousel>
          </div>
        </div>
      </div>
    </MainContainer>
  );
}
