import { MainContaper, SubTitle, ContentCard, Button } from "./About-style"
import HeadingH1 from "../../HeadingComponent/HeadingH1"
import { FaInfo } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function About() {
    const { t } = useTranslation()
    return (
        <>
            <MainContaper>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                            <ContentCard className="about-content">
                                <div className="title-effect title-effect-2">
                                    <div className="ellipse"></div> <span><FaInfo /></span>
                                </div>

                                <SubTitle>{t('aboutUs')}</SubTitle>
                                <HeadingH1 title={t('companyOverview')}></HeadingH1>
                                <p>{t('companyOverviewText')}</p>

                                <Button>
                                    <NavLink to="/about-us">{t('moreAboutUs')}</NavLink>
                                </Button>
                            </ContentCard>
                        </div>
                        {/* <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="aboutImg text-ccenter">
                                <img src="/assets/images/home/home-about.png" alt="about" />
                            </div>
                        </div> */}
                    </div>

                </div>
                <br></br>
            </MainContaper>
        </>

    )
}