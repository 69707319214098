import ITServicesCard from "../about/ITServicesCards";
import Banner from "../BannerComponent/Banner";
import { ServicesCards, MainCardsbox, ReadMoreButon } from "./Services-style";

import { NavLink } from "react-router-dom";
import Navbar from "../../Header/Navbar/Navbar";

import { useTranslation } from 'react-i18next';
export default function Services() {
    const { t } = useTranslation();
    return (
        <>
            <Navbar />
            <Banner title={t('services')} subtitle={t('servingBest')} homeurl="/" activelink="Services" />
            <ServicesCards>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 services-box">
                            <MainCardsbox>
                                <ITServicesCard image="assets/images/services/WechatIMG1.jpg" title={t('assistanceVariousThaiVisas')} />
                                <ReadMoreButon type="button">
                                    <NavLink to="/visas">{t('readMore')}</NavLink>
                                </ReadMoreButon>
                            </MainCardsbox>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 services-box">
                            <MainCardsbox>
                                <ITServicesCard image="assets/images/services/WechatIMG2.jpg" title={t('comprehensiveLegalRegulatoryAffairs')} />
                                <ReadMoreButon type="button">
                                    <NavLink to="/legal">{t('readMore')}</NavLink>
                                </ReadMoreButon>
                            </MainCardsbox>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 services-box">
                            <MainCardsbox>
                                <ITServicesCard image="assets/images/services/WechatIMG3.jpg" title={t('bankCardDriversLicenseAssistance')} />
                                <ReadMoreButon type="button">
                                    <NavLink to="/bank-account">{t('readMore')}</NavLink>
                                </ReadMoreButon>
                            </MainCardsbox>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 services-box">
                            <MainCardsbox>
                                <ITServicesCard image="assets/images/services/WechatIMG4.jpg" title={t('condominiumVillaSalesLeasingServices')} />
                                <ReadMoreButon type="button">
                                    <NavLink to="/real-estate">{t('readMore')}</NavLink>
                                </ReadMoreButon>
                            </MainCardsbox>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 services-box">
                            <MainCardsbox>
                                <ITServicesCard image="assets/images/services/WechatIMG5.jpg" title={t('comprehensiveBusinessSetupServices')} />
                                <ReadMoreButon type="button">
                                    <NavLink to="/company-reg">{t('readMore')}</NavLink>
                                </ReadMoreButon>
                            </MainCardsbox>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 services-box">
                            <MainCardsbox>
                                <ITServicesCard image="assets/images/services/WechatIMG6.jpg" title={t('premiumCarSales')} />
                                <ReadMoreButon type="button">
                                    <NavLink to="/car-service">{t('readMore')}</NavLink>
                                </ReadMoreButon>
                            </MainCardsbox>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 services-box">
                            <MainCardsbox>
                                <ITServicesCard image="assets/images/services/WechatIMG7.jpg" title={t('additionalServicesThailand')} />
                                <ReadMoreButon type="button">
                                    <NavLink to="/additional-service">{t('readMore')}</NavLink>
                                </ReadMoreButon>
                            </MainCardsbox>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 services-box">
                            <MainCardsbox>
                                <ITServicesCard image="assets/images/services/WechatIMG8.jpg" title={t('internationalConferencesPressConferences')} />
                                <ReadMoreButon type="button">
                                    <NavLink to="/media">{t('readMore')}</NavLink>
                                </ReadMoreButon>
                            </MainCardsbox>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 services-box">
                            <MainCardsbox>
                                <ITServicesCard image="assets/images/services/WechatIMG9.jpg" title={t('ITServices')} />
                                <ReadMoreButon type="button">
                                    <NavLink to="/tech">{t('readMore')}</NavLink>
                                </ReadMoreButon>
                            </MainCardsbox>
                        </div>
                    </div>
                </div>
            </ServicesCards>

        </>
    )
}