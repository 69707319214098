import Footer from "../Footer/Footer";
import Navbar from "../Header/Navbar/Navbar";
import Services from "../pages/Services/Services";
import About from "./About/About";
import Banner from "./Banner/Banner";
import HappyClients from "./HappyClientsComponent/HappyClients";
import OurTeam from "./OurTeamComponent/OurTeam";
import OurVisionMision from "./OurVisionMisionComponent/OurVisionMision";
import Testimonials from "./Testimonials/Testimonials";

export default function Home(){

    return(
        <>
          
           <Navbar />

           <Banner />

           <About />

           <OurVisionMision />

           <Services />

           <OurTeam />

           {/* <Testimonials /> */}

           {/* <HappyClients /> */}

           {/* <OurPortfolio /> */}

           <img className='footer-mobile-bg' src="/assets/images/bg/footer-bg.png" alt="Footer bg"/>

           <Footer ishome={true} />
 
        </>
    )
}