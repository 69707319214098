import React, {useEffect} from "react";
import Footer from "../../Footer/Footer";
import Navbar from "../../Header/Navbar/Navbar";
import HeadingH2 from "../../HeadingComponent/HeadingH2";
import HeadingH3 from "../../HeadingComponent/HeadingH3";
import ServicesBanner from "../ServicesBanner/ServicesBanner";
import { useTranslation } from "react-i18next";

export default function CarService() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <>
      <Navbar />

      <ServicesBanner
        deskbanner="assets/images/services/WechatIMG6.jpg"
        mobbanner="assets/images/services/WechatIMG6.jpg"
      />

      <div className="services-card-content">
        <div className="container">
          <HeadingH2 title={t("carService.title")} />

          <h2 className="svtitle">{t("carService.description")}</h2>

          <HeadingH3 title={t("carService.serviceDescription")} />

          <p>{t("carService.serviceDescription")}</p>

          <p>{t("carService.rentalDescription")}</p>

          <h2 className="svtitle">{t("carService.whyChooseUs")}</h2>

          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <ul>
                <li>{t("carService.feature1")}</li>
                <li>{t("carService.feature2")}</li>
                <li>{t("carService.feature3")}</li>
                <li>{t("carService.feature4")}</li>
                <li>{t("carService.feature5")}</li>
                <li>{t("carService.feature6")}</li>
              </ul>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <img
                className="container"
                src="/assets/images/car-service-benefits.jpeg"
                alt="benefits"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer ishome={false} />
    </>
  );
}
