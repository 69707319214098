import styled from "styled-components";

export const ServicesCards = styled.section`
   width: 100%;
`;

export const AboutCard = styled.div`
   width: 100%;
   .ab-content-card{
       img{
           width: 300px;
           
       }
       h1{
         font-family: 'Nunito', sans-serif;
       }
   }
`;

export const MainCardsbox = styled.div`
    width: 100%;
    padding: 18px 14px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    transition: all 0.4s ease-in-out;
    @media screen and (max-width: 576px){
        padding: 15px 10px;
    }
    &:hover{
        margin-top: -5px;
        box-shadow: 0px 4px 12px rgb(0 0 0 / 22%)
    }
`;

export const ReadMoreButon = styled.button`
    background: #012fa7;
    border-radius: 4px;
    color: #fff !important;
    padding: 8px 20px !important;
    border: none;
    margin-top: 10px;
    text-decoration: all 0.3s ease-in-out;
    a{
      text-decoration: none;
      color: #fff;
    }
`;
