import { OurTeamContainer, TeamRightCard } from "./OurTeams-style"
import { FaUsers } from "react-icons/fa";
import HeadingH3 from "../../HeadingComponent/HeadingH3";
import TeamCards from "./TeamCards";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useTranslation } from "react-i18next";

export default function OurTeam() {
  const { t } = useTranslation()
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <>
      <OurTeamContainer>
        <div className="container-fluid">
          <div className="mainteamrow row align-items-center">
            <div className="col-xl-5 col-lg-5 col-md-12">
              <div className="teamcontent">
                <div className="title-effect title-effect-2">
                  <div className="ellipse"></div> <span><FaUsers /></span>
                </div>
                <HeadingH3 title={t('expertTeam')} />
                <p>{t('jobDescription')}</p>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-md-12">
              <TeamRightCard>
                <Carousel responsive={responsive} autoPlay infinite={true} autoPlaySpeed={4000}>
                  <div><TeamCards image="assets/images/team/1.jpeg" title="Zeming Li" designation="Chairman and General Manager" /></div>
                  <div><TeamCards image="assets/images/team/2.jpeg" title="Lilian Liu" designation="Executive Director" /></div>
                  <div><TeamCards image="assets/images/team/3.jpeg" title="Lucien Liu" designation="Financial Director" /></div>
                  <div><TeamCards image="assets/images/team/4.jpeg" title="Nic" designation="Transport Manager" /></div>
                  <div><TeamCards image="assets/images/team/5.jpeg" title="Rui Li" designation="Global Visa Manager" /></div>
                  <div><TeamCards image="assets/images/team/6.jpeg" title="May Li" designation="Key Account Manager" /></div>
                  <div><TeamCards image="assets/images/team/7.jpeg" title="Lily" designation="Legal Director" /></div>
                  <div><TeamCards image="assets/images/team/8.jpeg" title="Yangyang" designation="Director of Transportation" /></div>
                  <div><TeamCards image="assets/images/team/9.jpeg" title="Jinmei Duan" designation="Visa Department" /></div>
                  <div><TeamCards image="assets/images/team/10.jpeg" title="Joey Zhang" designation="Legal Specialist" /></div>
                  <div><TeamCards image="assets/images/team/11.jpeg" title="Jokoe" designation="Transport Driver" /></div>
                  <div><TeamCards image="assets/images/team/12.jpeg" title="Qilin Wang" designation="Chief Assistant" /></div>
                  <div><TeamCards image="assets/images/team/13.jpeg"  title="Sunjay Kumar" designation="Chief Technology Officer"/></div>
                </Carousel>
              </TeamRightCard>
            </div>
          </div>
        </div>
      </OurTeamContainer>
    </>
  )
}