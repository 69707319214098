import styled from "styled-components";

export const MainContainer = styled.section`
   width: 100%;
   padding: 20px 20px;
   position: relative;
   
   .mvision{
       @media screen and (max-width: 576px){
          
       }
   }
`;

export const ContentCard = styled.div`
    width: 100%;
    h2{
        margin-top: 10px;
    }
    .vm-mobile-view{
        display: none;
        @media screen and (max-width: 767px){
            margin-top: 15px;
            margin-bottom: 20px;
            display: block;
        }
    }
    p{
        text-align: justify;
    }
  
`;

export const ImageCard = styled.div`
    width: 100%;
    position: relative;
    &.vm-desktop-view {
     
       @media screen and (max-width: 767px) {
           display: none;
       }
    }
`;


export const MisionLisit = styled.ul`
    list-style: none;
    margin: 0px;
    margin-bottom: 10px;
    padding: 0px;
    column-count: 2;
    @media screen and (max-width: 576px) {
        column-count: 1;
        margin-bottom: 6px;
    }
`;

export const List = styled.li`
    margin-bottom: 10px;
    position: relative;
    padding-left: 20px;
    &::before {
        content: "";
        background: #2575fc;
        position: absolute;
        top: 12px;
        left: 0;
        width: 10px;
        height: 2px;
        border-radius: 4px;
    }
`;

export const Button = styled.button`
     padding: 10px 25px;
     background: #012fa7;
     border: none;
     color: #fff;
     height: 46px;
     border-radius: 7px;
     font-weight: 500;
     font-size: 16px;
     transition: all 0.4s ease-in-out;
     margin-top: 10px;
     @media screen and (max-width: 576px){
        height: 40px;
        padding: 8px 15px;
        font-size: 15px;
     }
     &:hover{
          transform: translateY(-3px); 
     }
     a{
          text-decoration: none;
          color: #fff;
     }
`;